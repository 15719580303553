import axios from "axios";
import { NOMINATIM_URL } from "../const";


const NominatimClient = axios.create({
    baseURL: NOMINATIM_URL,
    // headers: {
    //     'Access-Control-Allow-Origin': '*'
    // }
});

export const nominatimSearch = (state, city, value) => {
    const params = new URLSearchParams({
      state: state || '',
      city: city || '',
      value: value || ''
    });
  
    return NominatimClient.get(`/search?${params.toString()}`);
  };
  

export const nominatimReverse = (latitude, longitude) => {
    return NominatimClient.get(
        `/reverse?lat=${latitude}&lon=${longitude}&addressdetails=1&format=json`
    )
}
